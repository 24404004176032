.portfolio-card {
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 16%);
    background-color: #ffffff;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 20px;
    cursor: pointer;
    button {
        border: none !important;
        outline: none !important;
        border-color: #ffffff !important;
    }
    .title {
        box-shadow: 0 1px 4px 0 rgb(0 0 0 / 16%);
        background-color: #ffffff;
        color: #5ae3a7;
        margin: 30px;
        padding: 12px;
        border-radius: 40px;
    }
    img {
        border-radius: 12px;
    }
}
