/*--------------------------------------
/*  Brand Css Start
----------------------------------------*/

.single-brand {
    text-align: center;
    .thumb {
        display: block;
    }
    img {
        height: 100px !important;
    }
}

/*--------------------------------------
/*  Brand Css ENd
----------------------------------------*/
